@import '../../common/common'
  
.details-group
  height: calc(50% - 1em) 
  padding: 0.5em

  &.right-align
    text-align: right
  &.left-algin
    text-align: left

.label
  font-size: .8em
  margin-bottom: .3em

.location
  font-size: 1.2em
  font-weight: 600

.level
  height: 1.2em
  width: 5.5em
  background: white
  border-radius: 1em
  margin-top: 0.3em
  display: flex
  align-items: center
  border: 1px solid black

  .level-bar
    background: rgb(0,0,0, .5)
    height: 90%
    border-radius: 1em
    margin-left: 0.1em
  
.status-indicators
  display: flex

  .status-icon
    border: 1.2px solid $LIGHT_GRAY
    border-radius: 100%
    width: 1em
    height: 1em
    margin: .2em

  &.status-good
    .good
      background: #1FDA70
      
  &.status-warning
    .warning
      background: orange

  &.status-critical  
    .critical
      background: red