@import '../common/common'

.settings-container
  padding: 0 1em 1.5em

  .settings-header
    @include centerLayout
    @include headerGeneral
    flex-direction: column
    width: 100%
    height: 1.5em

  .action
    padding: .5em

  .app-info-container
    position: relative
    margin: .5em

    .app-version
      position: absolute
      top: .7em

    .icon-sage
      width: 15em

  .company-info
    margin-top: 1em

  .company-container
    display: flex
    flex-direction: row
    align-items: center
    margin: 1em 0

    .address
      line-height: 1.3
      margin-left: 1em