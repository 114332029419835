$CYAN: #00afec
$LIGHT_BLUE: #E2EAED

$MEDIUM_GREEN: #6ABC5C

$SHADE_GRAY: #F5F5F5
$LIGHT_GRAY: #c6c7c6
$MEDIUM_GRAY: #6B6B6F
$CHARCOAL_GRAY: #494a49
$DARK_GRAY: #2B2B2E

$header-height: 3em
$footer-height: 6.5em
$max-app-width: 34em

@mixin centerLayout
  display: flex
  justify-content: center
  align-items: center

@mixin headerGeneral
  font-size: 2em
  margin: 0em auto .2em
  font-weight: 600

@mixin dark-theme
  background: $CHARCOAL_GRAY
  color: white

  .heading
    color: $MEDIUM_GREEN