@import '../common/common'
  
.products-page-container
  flex-direction: column
  padding: 0 1.5em
  height: calc(100% - ($header-height + $footer-height))
  position: relative

  .products-header
    @include centerLayout
    @include headerGeneral
    flex-direction: column
    width: 100%
    height: 1.5em

  .products-description
    margin-bottom: 1em
    text-align: center
    line-height: 1.3

.products-content
  width: 100%
  overflow-y: scroll
  display: grid
  --content-width: 100%
  --product-width: calc(var(--content-width) / 2 - .5em)
  grid-template-columns: var(--product-width) var(--product-width)
  gap: 1em
  margin-bottom: 1em

  &::-webkit-scrollbar
    display: none
  
  .product-container
    @include centerLayout
    flex-direction: column
    
  .product-image-group
    background: $CHARCOAL_GRAY
    width: 100%
    height: 6.5em
    @include centerLayout
    padding-bottom: 2rem

    img
      width: 80%
      padding-top: 2rem

  .label-group
    @include centerLayout
    flex-direction: column
    background: $MEDIUM_GRAY
    width: 100%
    height: 3.5em
    color: white
    text-shadow: 1px 1px 9px grey

    .product-name
      font-weight: 600

      font-size: 1.3em
     
    .product-unit
      padding-top: .2em



