@import '../common/common.sass'

.login-container
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  
.login-content
  width: 100%

.logo-group,
.logo
  display: flex
  justify-content: center
  align-items: center

.logo-group
  padding-top: 1em
  padding-bottom: 1em
  height: 4em
  @include centerLayout

.logo
  height: 7em
  width: 8em
  transform: rotate(90deg)
  @include centerLayout

.login-form
  padding: 4em 1em 0
  position: relative

  .login-warning
    position: relative
    color: darkorange
    text-align: center
    top: 0.5em
    padding-bottom: 0.5em
    width: calc(100% - 0.1em)

  .action
    padding: 0.5em
    margin-top: 1em

.footer-container
  padding: 0.7em 0.5em
  display: flex
  justify-content: space-between
  