@import '../common/common.sass'
  
.scheduler-container
  width: 100%

  .scheduler-section
    padding: 0 0.5em 1em

  .scheduler-heading
    padding: 0 .5em

  .scheduler-label
    text-transform: uppercase
    padding-bottom: .3em
    font-weight: 400
    letter-spacing: 0.1em

  .divider
    border-top: 1px solid $LIGHT_GRAY

  .input-description
    font-size: 1.2em
    font-weight: 400
    margin-left: .2em

  .scheduler-input
    display: flex
    flex-direction: row
    align-items: center

    .input-field-container
      padding-top: .2em

    &.duration
      .input-field-container
        width: 2.7em

    &.schedule
      .input-field-container
        width: 5em

  .schedule-time-container
    display: flex
    flex-direction: row
    align-items: center
    padding-left: .5em

    .input-description
      padding-right: .5em

    .input-field-container
      width: 3.3em
      padding: 0.2em


      &.selection
        width: 5em

.scheduler-container .input-field, .scheduler-container select
     background-color: $LIGHT_BLUE
     color: $DARK_GRAY