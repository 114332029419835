@import '../common/common.sass'

.menu-container
  width: 100vw
  height: $footer-height
  display: flex
  justify-content: center

  background:$DARK_GRAY
    
  position: fixed
  bottom: 0
  right: 0

  .menu-items
    width: 100%
    max-width: $max-app-width
    display: flex
    justify-content: space-around

  .tab-group
    @include centerLayout
    flex-direction: column
    text-align: center
    width: calc(100% / 4)

    .menu-item
      text-decoration: none
      color: inherit
      display: flex
      align-items: center
      flex-direction: column
      justify-content: center

      .icon
        fill: $MEDIUM_GREEN

      &.is-active
        .icon
          fill: white
          path
            fill: white

  .tab-icon
    height: 3.8em
    width: 3.8em
    background: rgb(0, 0, 0, .3)
    
  .tab-label
    padding-top: .7em
    font-size: .9em
    font-weight: 600
