@import './components/common/common.sass'

@import 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,300&display=swap'


body
  margin: 0
  font-family: 'Poppins', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  line-height: 1
  background-color: $DARK_GRAY

  input
    -webkit-user-select: text
    -khtml-user-select: text
    -moz-user-select: text
    -ms-user-select: text
    user-select: text

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

#root
  width: 100vw
  max-width: $max-app-width
  height: 100vh
  margin: auto
  color: white

  button
    cursor: pointer

  @media screen and (max-width: 300px)
    font-size: .8em