@import '../common/common'
  
.service-container
  flex-direction: column
  padding: 0 1em 1.5em
  
.service-header
  @include centerLayout
  @include headerGeneral
  flex-direction: column
  width: 100%
  height: 1.5em

.service-description
  margin-bottom: 1em
  text-align: center
  
.service-form
  width: 100%

  .service-textarea
    height: 13em
  
  .action
    padding: .5em