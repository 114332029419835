@import '../common/common.sass'

.home-container
  @include centerLayout
  height: 100%
  flex-direction: column
  padding: 0 1em
  justify-content: space-evenly
  
.home-header-group
  @include centerLayout
  flex-direction: column
  width: 100%

  .header
    @include headerGeneral
  
  .header-stats
    margin-top: .5em
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%

.home-content
  width: 100% 

.video-container
  @include centerLayout
  height: calc(100% * 9/16)
  height: 300px
  background: $CHARCOAL_GRAY
  margin-bottom: .5em
  position: relative
  overflow: hidden

  .loader-text
    display: flex
    justify-content: center
    align-items: center
    position: absolute

  img
    width: 100%

  video
    height: 100%
    width: 100%

  .video-label
    position: absolute
    bottom: .5em
    right: .5em

    height: 1.5em
    font-size: .8em
    color: white
    border: 1px solid white
    padding: 0.3em 0.5em 0

.general-stats-group
  display: flex
  flex-direction: row

  .general-stat
    display: flex
    justify-content: center
    align-items: center
    flex-grow: 1
    height: 5em
    background: $CHARCOAL_GRAY

    &:not(:last-child)
      margin-right: .5em

.home-stats-container
  width: 100%

.stats-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 1em

.stats-label
  font-size: 1.2em
  .main
    font-weight: 600
 
.stats-selector
  display: flex
  flex-direction: row
  align-items: center

  .icon-down-arrow
    margin: 0 0.2em

.snapshots-container
  display: grid
  font-weight:700
  --container-width: 100%
  --snapshot-width: calc(var(--container-width) / 2 - .5em)
  grid-template-columns: var(--snapshot-width) var(--snapshot-width)
  gap: 1em
  margin: 1em 0

.snapshot-group
  display: flex
  flex-direction: column
  justify-content: center
  
  .snapshot-label-group
    background: $MEDIUM_GRAY
    color: white
    @include centerLayout
    height: 2em
  
  .snapshot-label
    font-size: .8em

.snapshot-content
  @include centerLayout
  background: $CHARCOAL_GRAY
  height: 4em
  flex-direction: column

  .snapshot-value
    font-size: 1.4em

  .snapshot-description
    font-size: .8em
    padding-top: .5em
    font-weight:400
    
    &.aqi,
    &.health
      text-transform: uppercase
    
.snapshot-img
  padding-top: 1rem
  background: $CHARCOAL_GRAY
  @include centerLayout