@import '../common/common.sass'

.main-container
  height: calc(100% - ($footer-height + $header-height))
  padding-top: $header-height
  overflow: scroll

  &.isModalActive
    overflow: hidden
    height: 100vh
    padding-top: 0

    .main-content
      height: 100vh

    .backdrop
      background: rgba(0, 0, 0, 0.2)
      width: 100%
      height: 100%
      position: fixed
      left: 0
      top: 0
      backdrop-filter: blur(.5em)
      z-index: 15

  &::-webkit-scrollbar
    display: none

  .nav-top
    display: flex
    flex-direction: row
    justify-content: space-between

    height: $header-height
    position: fixed
    top: 0
    right: 0
    
    width: 100vw
    z-index: 10
    padding-top: .5em

  .alert-icon-container,
  .watering-icon-container
    all: unset
    cursor: pointer
    width: 2.5em
    height: 2.5em
    margin: .7em 1em 0em

  .watering-icon-container
    position: relative

    .icon-droplette
      position: absolute
      top: -0.5em
      right: 0em

  .alert-icon-container
    position: relative

    .alert-count
      position: absolute
      top: 0
      right: .7em

      display: flex
      justify-content: center
      align-items: center

      font-size: .6em
      font-weight: 700
      color: white

      width: 1.6em
      height: 1.6em
      border-radius: 100%
      border: 1px solid white
      background: #FD6060

  .watering-modal-container
    position: fixed
    top: 0
    width: 100vw
    max-width: $max-app-width
    height: 100%
    color: $CHARCOAL_GRAY
    @include centerLayout
    padding-top: 2em
    overflow: scroll
    display: none

    &.isActive
      display: flex
      z-index: 30

    .modal-content
      position: relative
      width: 100%
      padding: 2em 1em

      margin: 1em
      background: white
      @include centerLayout
      flex-direction: column
      justify-content: space-evenly

      .exit-button
        all: unset
        cursor: pointer
        position: absolute
        top: 1.3em
        right: 1.3em

        .icon-exit
          fill: $MEDIUM_GREEN
          width: 2em
          height: 2em

    .modal-label
      text-transform: uppercase
      font-weight: 400
      letter-spacing: 0.1em
      font-size: 1em

    .modal-header
      font-size: 2em
      font-weight: 700
      margin: .5em

    .input-field-container
      width: calc(100% - 3em)

    .actions-container
      @include centerLayout
      margin-top: 1em
      flex-direction: column
      width: calc(100% - 3em)

      button
        margin: .5em 0 

      .button-stop
        background: $CHARCOAL_GRAY
        border: 1px solid white

.page-content
  width: 100%