@import '../common'
  
.input-field-container
  padding: .5em

.input-label
  font-size: 1.1em
  margin-bottom: 0.3em

.input-field
  width: 100%
  height: 3em
  position: relative
  background-color: $CHARCOAL_GRAY
  color: white
  transition: 0.3s all
  --input-field-inner-padding: 1em

  &:not(.selection-field)
    box-shadow: inset 0px 2px 5px $LIGHT_GRAY
  
  &.selection-field
    select
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none

    .icon-down-arrow
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 1em
      pointer-events: none
  
  input,
  textarea,
  select
    font-family: "Poppins", sans-serif
    font-size: 1em
    width: 100%
    border: none
    background-color: $CHARCOAL_GRAY
    color: white
    outline: none

  input,
  select
    height: 3em
    padding-left: var(--input-field-inner-padding)

  input
    width: calc(100% - var(--input-field-inner-padding))

  textarea
    padding: var(--input-field-inner-padding)
    width: calc(100% - 2 * var(--input-field-inner-padding))
    height: calc(100% - 2 * var(--input-field-inner-padding))

.input-field input::-webkit-input-placeholder 
  color: rgba(255, 255, 255, 0.8)

.input-field input::-moz-placeholder 
  color: rgba(255, 255, 255, 0.8)

.input-field input:-ms-input-placeholder 
  color: rgba(255, 255, 255, 0.8)

.input-field input:-moz-placeholder 
  color: rgba(255, 255, 255, 0.8)

.dropdown-container .input-field input
    background: $DARK_GRAY

.scheduler-container input
     background-color: $LIGHT_BLUE
     color: $DARK_GRAY
