@import '../common.sass'
  
.main-button
  width: 100%
  height: 2.5em
  font-size: 1.2em
  color: white
  border: none
  background: $MEDIUM_GREEN
  @include centerLayout

.button-text
  font-weight: 600
  font-size: 1.2em
  text-shadow: 3px 3px 8px grey
  font-family: 'Poppins', sans-serif