@import '../common'

.dropdown-container
  margin: 0.4em 0.5em
  background-color: $CHARCOAL_GRAY
  --dropdown-field-height: 3em
  min-height: var(--dropdown-field-height)

  .input-field-container
    .input-field
      background: $DARK_GRAY
      color: white

  select
    background: $DARK_GRAY
    color: white

.dropdown-field
  width: 100%
  position: relative
  transition: 0.3s all
  display: flex
  align-items: center
  &.isExpanded
    .icon-carrot
      transform: rotate(0deg)

  .field-heading-group
    display: flex
    flex-direction: row
    align-items: center
    height: var(--dropdown-field-height)

  .icon-carrot
    padding: 0 0.5em
    transform: rotate(-90deg)

  .field-label
    margin-left: 0.5em
    font-size: 1.2em

  


.short-fields-group
  display: flex
  flex-direction: row

  .input-field-container
    width: calc(50% - 1em)

 